import "@progress/kendo-ui";
import moment from "moment";
import { parametriPreventivatoreItem } from "./models/carrello/parametriPreventivatore";
import { RigaCarrello } from "./models/carrello/rigaCarrello";
import { DayOfWeek } from "./models/dayOfWeek";
import { TextValueItem } from './models/TextValueItem';
import apiFile from "@/services/apiFile";
import api from "./services/api";

const helpers = {
	defaultPageSize: 25,
	popupCarrello: [],

	//#region ** API **
	toQueryString: function (params) {
		var queryString = Object.keys(params)
			.map(function (key) {
				return key + "=" + params[key];
			})
			.join("&");

		return queryString;
	},
	/*
	strFiltro: function (data, filtroBase, filtroUlteriore) {
		var strWhere = "";

		if (filtroBase)
			strWhere += filtroBase;

		if (data.filter) {
			for (var j = 0; j < data.filter.filters.length; j++) {
				var filtro = data.filter.filters[j],
					value = filtro.value;
				if ((isNaN(value) || value.getMonth) && kendo.parseDate(value) != null) {
					value = kendo.toString(kendo.parseDate(value), "yyyy-MM-dd");
				}

				if (strWhere.length > 0)
					strWhere += ' and ';
				if (filtro.operator == 'eq')
					strWhere += filtro.field + "='" + value + "'";
				if (filtro.operator == 'neq')
					strWhere += filtro.field + " <> '" + value + "'";
				if (filtro.operator == 'startswith')
					strWhere += filtro.field + " like '" + value + "%'";
				if (filtro.operator == 'contains')
					strWhere += filtro.field + " like '%" + value + "%'";
				if (filtro.operator == 'doesnotcontain')
					strWhere += filtro.field + " not like '%" + value + "%'";
				if (filtro.operator == 'endswith')
					strWhere += filtro.field + " like '%" + value + "'";
				if (filtro.operator == 'gte')
					strWhere += filtro.field + ">='" + value + "'";
				if (filtro.operator == 'gt')
					strWhere += filtro.field + ">'" + value + "'";
				if (filtro.operator == 'lte')
					strWhere += filtro.field + "<='" + value + "'";
				if (filtro.operator == 'lt')
					strWhere += filtro.field + "<'" + value + "'";
			}
		}

		if (filtroUlteriore && filtroUlteriore.length > 0)
			strWhere += ' and ' + filtroUlteriore;

		return strWhere;
	},
	*/
	buildUrlParams: function (options): string[] {
		var p = [];
		options.page && p.push("page=" + options.page);
		options.pageSize && p.push("pageSize=" + options.pageSize);

		var newFilter = options.filter && options.filter.filters && options.filter.filters.length ? this.buildFilterObject(options.filter) : "";
		newFilter && p.push("filter=" + newFilter);

		options.fullTextSearch && p.push("fullTextSearch=" + options.fullTextSearch);

		//options.group && options.group.length && (p.push("sort=" + this.buildSortString(options.group)));
		options.sort && options.sort.length && p.push("sort=" + this.buildSortString(options.sort));

		return p;
	},

	buildFilterObject: function (filter) {
		var that = this;

		var build = function (filterObject) {
			var logic = filterObject.logic,
				filters = filterObject.filters,
				w = [];

			for (var i = 0; i < filters.length; i++) {
				var f = filters[i],
					newWhere = "";
				if (f.filters)
					// altro array di filtri
					newWhere = build(f);
				else newWhere = that.getFieldString(f);

				newWhere && w.push(newWhere);
			}

			return w.length ? "(" + w.join(";" + (logic === "or" ? "*" : "")) + ")" : "";
		};

		var filterString = build(filter);

		return filterString;
	},

	getFieldString: function (filter) {
		var op = filter.operator,
			field = filter.field,
			value = filter.value;

		if ((isNaN(value) || value.getMonth) && kendo.parseDate(value) != null) {
			value = "!!date=" + kendo.toString(kendo.parseDate(value), "yyyy-MM-ddTHH:mm:ss");
		}

		if (value === true || value === false) value = "!!" + value + "!!";

		if (typeof value === "number") value = "!!n=" + (value || value == 0 ? value.toString().replace(".", ",") : 0);

		if (op === "eq") return field + "[eq]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "neq") return field + "[neq]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "contains") return field + "[co]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "doesnotcontain") return field + "[nco]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "startswith") return field + "[sw]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "endswith") return field + "[ew]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "gte") return field + "[gte]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "lte") return field + "[lte]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "gt") return field + "[gt]" + (value ? value.replaceAll("&", "%26") : value);
		if (op === "lt") return field + "[lt]" + (value ? value.replaceAll("&", "%26") : value);

		return "";
	},

	buildSortString: function (sortArray) {
		var ord = [];

		for (var i = 0; i < sortArray.length; i++) {
			var s = sortArray[i];
			ord.push((s.dir && s.dir === "desc" ? "-" : "") + s.field);
		}

		return ord.join(",");
	},

	//#endregion ** API **

	//#region ** GENERIC **

	GetDayNameByWeekDay(day: string) {
		switch (day) {
			case DayOfWeek.monday.toString():
				return "generico.monday";
				break;
			case DayOfWeek.tuesday.toString():
				return "generico.tuesday";
				break;
			case DayOfWeek.wednesday.toString():
				return "generico.wednesday";
				break;
			case DayOfWeek.thursday.toString():
				return "generico.thursday";
				break;
			case DayOfWeek.friday.toString():
				return "generico.friday";
				break;
			case DayOfWeek.saturday.toString():
				return "generico.saturday";
				break;
			case DayOfWeek.sunday.toString():
				return "generico.sunday";
				break;

			default:
				break;

				return "";
		}
	},

	// Turn enum into array
	ToArray(enumme) {
		return Object.keys(enumme)
			.filter(function (item) {
				return isNaN(Number(item)) === false
			})
			.map(key => enumme[key]);
	},

	cloneObject(obj: any) {
		if (obj) {
			return JSON.parse(JSON.stringify(obj));
		}

		return null;
	},

	optionsDateTimePicker() {
		return {
			//format: 'DD/MM/YYYY HH:mm:ss',
			extraFormats: ["YYYY-MM-DDThh:mm:ssZ"],
			useCurrent: false,
			showClear: true,
			showClose: true,
			locale: "it",
			icons: {
				time: "fa fa-clock",
				date: "fa fa-calendar",
				up: "fa fa-arrow-up",
				down: "fa fa-arrow-down",
				previous: "fa fa-chevron-left",
				next: "fa fa-chevron-right",
				today: "fa fa-clock",
				clear: "fa fa-trash"
			},
			tooltips: {
				today: "Oggi",
				clear: "Cancella selezione",
				close: "Chiudi",
				selectMonth: "Seleziona mese",
				prevMonth: "Mese precedente",
				nextMonth: "Mese successivo",
				selectYear: "Seleziona anno",
				prevYear: "Anno precendente",
				nextYear: "Anno successivo",
				selectDecade: "Seleziona decade",
				prevDecade: "Decade precedente",
				nextDecade: "Decade successiva"
			}
		};
	},

	getMaxDate() {
		return new Date(2099, 11, 31);
	},

	getToday() {
		return moment().startOf('day').toDate();
	},

	getParametroValidazioneCarrello(parametriProdotto, nomePar) {
		var par = parametriProdotto || null;
		if (!par)
			return null;
		for (var i = 0; i < par.length; i++)
			if (par[i].parametro === nomePar)
				return par[i].valore;
		return null;
	},

	getExtraInfoCarrello(extraInfo, labelID) {
		for (var i = 0; i < extraInfo.length; i++) {
			if (extraInfo[i].labelID === labelID)
				return extraInfo[i];
		}
		return null;
	},

	addDaysToDate(date, daysToAdd) {
		date = moment(date).startOf('day');
		date = date.add(daysToAdd, "days");
		return date.toDate();
	},

	tipiExtraInfo: {
		limite30GG: 8,
		limiteEtaMin: 10,
		limiteEtaMax: 4,
		importoViaggioObbligatorio: 1,
		dataNascitaObbligatorio: 2,
		codFiscaleObbligatorio: 11,
		dataMinima: 6,
		dataMassima: 7,
		applicazioneImbarcazione: 3,
		applicazioneAlloggio: 9,
		complementareObbligatorio: 14
	},

	tipiTestoCarrello: {
		CodicePromozionale: 1,
		DataFine: 2,
		DataInizio: 3,
		Destinazione: 4,
		ImportoViaggio: 5,
		NazionePartecipanti: 6,
		NumeroAlloggi: 7,
		NumeroPartecipanti: 8,
		SecondoImporto: 9,
		ImportoViaggioPerAlloggio: 10,
		APersona: 11,
		Eta: 12
	},

	tipiTestoCarrelloDefault: {
		1: "Codice Promozionale",
		2: "Data Fine Viaggio",
		3: "Data Inizio Viaggio",
		4: "Destinazione",
		5: "Importo Viaggio",
		6: "Area di partenza",
		7: "Numero Alloggi",
		8: "Numero Partecipanti",
		9: "Importo ad Imbarcazione",
		10: "Per Alloggio",
		11: "a Persona",
		12: "Età"
	},

	getTipoTestoCarrelloDefault(tipoID) {
		return this.tipiTestoCarrelloDefault[tipoID] || "";
	},

	getBase64Image(src, fallbackImage) {
		//var url = `${this.$appConfig.baseApiUrl}/file/image/${self.src}${self.pathFallback != null ? '?fallback=' + self.pathFallback : ''}`;

		return new Promise(function (resolve, reject) {
			apiFile.downloadImage(src)
				.then(function (resp) {
					var mimeType = resp.headers['content-type'].toLowerCase();
					// var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
					var imgBase64 = Buffer.from(resp.data).toString('base64');
					//self.srcImag = 'data:' + mimeType + ';base64,' + imgBase64;
					resolve('data:' + mimeType + ';base64,' + imgBase64);
				}).catch((function () {
					resolve(src || fallbackImage);
				}));
		});
	},

	async getTerminiECondizioni(context, pacchettoID, prodottoID) {
		var self = this;

		if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
			api.getWithAllDataResponse(`${api.endpoint.CARRELLO.TERMINI_E_CONDIZIONI}?pacchettoId=${pacchettoID || null}&prodottoId=${prodottoID || null}`, { responseType: "blob" })
			.then(res => {
				const blob = new Blob([res.data], { type: res.headers['content-type'] });

				if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
					(window.navigator as any).msSaveOrOpenBlob(blob);
				} else {
					var fileURL = URL.createObjectURL(blob);
					window.open(fileURL, "_self");
				}
			}).catch(err => {
				self.$bvToast.toast(self.$i18n.t("errore.fileNotFound").toString(), {
					variant: "danger",
					title: self.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});
		}
		else{
			
            //const url = `${api.endpoint.CARRELLO.TERMINI_E_CONDIZIONI}?pacchettoId=${pacchettoID || null}&prodottoId=${prodottoID || null}`;
            const url = `${process.env.VUE_APP_BASE_URL}/carrello/terminiecondizioni?pacchettoId=${pacchettoID || null}&prodottoId=${prodottoID || null}`;
           
			window.open(url, "_blank");
				
			/* const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);*/
        }
		
	},


	// async getPopupCarrello() {
	// 	var res = await api.get(`${api.endpoint.CARRELLO.POPUP}`);
	// 	this.popupCarrello = res || [];
	// },

	// async openPopupCarrello(popup, context) { // passare this come context
	// 	var html = "";

	// 	if (popup.pathFile) {
	// 		var src = popup.pathFile;
	// 		if (!src.startsWith("http")) {
	// 			var url = `${context.$appConfig.baseApiUrl}/file/image/${popup.pathFile}`;
	// 			src = await helpers.getBase64Image(url, '');
	// 		}
	// 		html = "<img src='" + src + "' style='width:100%'/>";

	// 		if (popup.link)
	// 			html = "<a href='" + popup.link + "' target='_blank'>" + html + "</a>";
	// 	}

	// 	if (popup.descrizione) {
	// 		html += "<div>" + popup.descrizione + "</div>";
	// 	}

	// 	var message = context.$createElement("div", {
	// 		domProps: { innerHTML: html }
	// 	});

	// 	context.$bvModal.msgBoxOk([message], {
	// 		title: popup.titolo || "",
	// 		okVariant: "",
	// 		okTitle: "Chiudi",
	// 		cancelVariant: "outline-theme",
	// 		footerClass: "",
	// 		headerBgVariant: "",
	// 		modalClass: "modalePopup"
	// 		// centered: true
	// 	})
	// },

	posizioniPopup: {
		login: 1, // home -> path '/'
		carrello: 2, // carrello/carrelloCategorie
		sceltaProdotto: 3, //infoProdotto
		myOverins: 4 //home di myoverins
	},
	modalitaVisualizzazioniPopup: {
		popup: 1,
	},
	GetTipologiaFromItem(item) {
		var result = "";

		if (item.isBoolean) {
			return 1;
		} else if (item.isPercentage) {
			return 2;
		} else if (item.isCurrency) {
			return 3;
		} else if (item.isDecimal) {
			return 4;
		} else if (item.isInt) {
			return 5;
		} else if (item.isDropdown) {
			return 6;
		} else if (item.isDatetime) {
			return 7;
		} else {
			return 0;
		}
	},

	getTipoApplicazioneLabelAlloggi: function (tipoApplicazione) {
        if (!tipoApplicazione)
            return "";
        switch (tipoApplicazione) {
            case 1:
                return "Tratte";
			case 2:
				return "Alloggi";
            case 3:
                return "Imbarcazioni";
            case 4:
                return "Unita' Abitative";
            default:
                return "";
        }
    },

	getUlterioriDatiValore: function (item) {
        if (item.tipo.startsWith("combo")) {
            var dsCombo = JSON.parse( item.tipo.substring(5) );
            //var dsCombo = [{ Codice: "M", Descrizione: "Maschio" }, { Codice: "F", Descrizione: "Femmina" }];
            //'combo[{"Codice":"M","Descrizione":"Maschio"},{"Codice":"F","Descrizione":"Femmina"}]'
            if (item.valore) {
                var val = dsCombo.find(function (obj) { return obj.Codice === item.valore });
                return val ? val.Descrizione : "";
            }
            return "";
        }
        else
            return item.valore || "";
    },

	delay(millisec) {
		return new Promise(resolve => {
			setTimeout(() => { resolve('') }, millisec);
		})
	}

	//#endregion
};

export default helpers;
