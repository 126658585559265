import { PartecipantiCarrello } from "./partecipantiCarrello";

export class parametriPreventivatoreItem {
	inizioViaggio: Date | null;
	fineViaggio: Date | null;
	destinazioneGruppoStato: number | null;
	destinazioneStato: number | null;
	nazionalita: number | null;
	eta: number | null;
	etaRange: any | null;
	tipologiaPolizza: number | null;
	numeroPartecipanti: number | null;
	importoViaggio: number | null;
	secondoImporto: number | null;
	numeroAlloggi: number | null;
	codicePromo: string;
	categoriaID: number | null;
	partecipanti: PartecipantiCarrello[]

	prodottiSelezionati: number[] | null;

	constructor(){
		this.inizioViaggio = null;
		this.fineViaggio = null;
		this.destinazioneGruppoStato = null;
		this.destinazioneStato = null;
		this.nazionalita = null;
		this.eta = null;
		this.etaRange = null;
		this.tipologiaPolizza = null;
		this.numeroPartecipanti = null;
		this.importoViaggio = null;
		this.secondoImporto = null;
		this.numeroAlloggi = null;
		this.codicePromo = null;
		this.categoriaID = null;
		this.partecipanti = [];

		this.prodottiSelezionati = [];
	}
}